import axios from "axios";

const apiEndPointMdc = process.env.REACT_APP_MDC_URL;
const apiEndPointCiap = process.env.REACT_APP_CIAP_URL;
const headersciap = {
    "x-functions-key": process.env.REACT_APP_CIAP_HEADER_KEY,
};
const headersmdc = {
    "x-functions-key": process.env.REACT_APP_MDC_HEADER_KEY,
};
export async function getHttpParamWithCiap(url, method, data) {
    try {
        return await axios({
            method: method,
            url: `${apiEndPointCiap}${url}`,
            headers: headersciap,
            data: data,
        });
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 0) {
            return error.response.status;
        }
        return "error";
    }
}
export async function getHttpParamWithMdc(url, method, data) {
    try {
        return await axios({
            method: method,
            url: `${apiEndPointMdc}${url}`,
            headers: headersmdc,
            data: data,
        });
    } catch (error) {
        if (error.response.status === 403 || error.response.status === 0) {
            return error.response.status;
        }
        return "error";
    }
}
