// @ts-nocheck
/* eslint-disable no-sparse-arrays */
import { Box, Table, Tbody, Tr, Td, Spinner } from "@chakra-ui/react";
import { TablePagination } from "react-pagination-table";
import "../Main/Main.css";
import { useEffect, useRef, useContext, useState } from "react";
import { apiUrl } from "../../Utility/ApiConstants";
import { getHttpParamWithMdc } from "../../Utility/Http";
import { TokenContext, MailChimpWriteContext } from "../../Utility/UserContext";

export default function Main() {
    const { type, tokenValue } = useContext(TokenContext);

    const { mailChimpFileWrite, setRateLimit } = useContext(
        MailChimpWriteContext
    );
    const data = useRef([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        getHttpParamWithMdc(
            `${apiUrl.getPrices}${type}=${tokenValue}`,
            "post"
        ).then((res) => {
            if (res === 403 || res === 0) {
                setRateLimit(true);
                setLoader(false);
                return;
            }
            if (res !== "error") {
                if (res.data.status_code === 200) {
                    res.data.prices_info.forEach((item) => {
                        data.current.push({
                            indexprice:
                                Math.ceil(item.indexprice) === 0
                                    ? "N/A"
                                    : `${item.indexprice} ${item.currency}`,
                            indexdescription:
                                item.fbdescription === "" ? (
                                    item.indexdescription
                                ) : (
                                    <>
                                        <a
                                            className="fbLink"
                                            href={item.link}
                                            target={"_blank"}
                                        >
                                            {item.fbdescription}
                                        </a>
                                        <br />
                                        {item.indexdescription}
                                    </>
                                ),
                            date: item.date === "" ? "N/A" : item.date,
                        });
                    });
                } else {
                    data.current = [];
                }

                mailChimpFileWrite();
            } else {
                data.current = [];
            }

            setLoader(true);
        });
    }, [tokenValue, type, mailChimpFileWrite, setRateLimit]);
    const Header = ["Description", "Price", "Date"];
    return (
        <div id="priceConfig">
            {!loader ? (
                <CreateTable
                    detail={
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="red.500"
                            size="lg"
                        />
                    }
                ></CreateTable>
            ) : data.current.length > 0 ? (
                <>
                    <TablePagination
                        id="tablepage"
                        className={"priceConfigBody"}
                        headers={Header}
                        data={data.current}
                        columns="indexdescription.indexprice.date"
                        perPageItemCount={10}
                        totalCount={data.current.length}
                        arrayOption={[[""]]}
                        paginationClassName={"pricePaginate"}
                        prePageText="Previous"
                        nextPageText="Next"
                        partialPageCount={3}
                    />
                </>
            ) : (
                <CreateTable
                    detail={
                        "No Data Available,  please contact your SCB representative."
                    }
                ></CreateTable>
            )}
        </div>
    );
}

function CreateTable(props) {
    return (
        <>
            <Box alignContent={"center"} id="priceConfigEmptyBody">
                <Table className="table">
                    <Tbody>
                        <Tr>
                            <Td>Description</Td>
                            <Td>Price</Td>
                            <Td>Date</Td>
                        </Tr>
                        <Tr>
                            <Td colSpan={3} id="emptyBodyText">
                                {props.detail}
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        </>
    );
}
