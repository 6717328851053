import React, { useState, useEffect } from "react";
import { apiUrl } from "../../Utility/ApiConstants";
import { getHttpParamWithCiap } from "../../Utility/Http";
import { Loader } from "../Common/Common";
import { RateLimit } from "../NoPage/NoPage";
import { useNavigate } from "react-router-dom";

const PortalClaim = () => {
    const [rateLimit, setRateLimit] = useState(false);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    function fetchToken(userEmail) {
        getHttpParamWithCiap(apiUrl.fetchToken, "post", {
            useremail: userEmail,
        }).then((res) => {
            if (res === 403 || res === 0) {
                setRateLimit(true);
                setLoader(false);
            }

            if (res !== "error" && res.data.status_code === 200) {
                const token = res.data.token;
                navigate(`/portals/portal?token=${token}`);
            } else {
                setLoader(false);
                window.location.href =
                    process.env.REACT_APP_NO_ACCESS_REDIRECT_URL;
                // navigate("/", { state: { ReportingHub: true } });
            }
        });
    }

    useEffect(() => {
        // get the authorization code from the url
        const urlParams = new URLSearchParams(window.location.search);
        const authorizationCode = urlParams.get("code");
        const postdata = { code: authorizationCode };

        if (authorizationCode !== "" || authorizationCode !== undefined) {
            getHttpParamWithCiap(
                apiUrl.fetchReportingHubUser,
                "post",
                postdata
            ).then((res) => {
                if (res === 403 || res === 0) {
                    setRateLimit(true);
                    setLoader(false);
                } else if (res !== "error" && res.data.status_code === 200) {
                    let userEmail = res.data.useremail;

                    // Assuming the pattern is always "live.com#EMAIL"
                    const parts = userEmail.split("#");

                    // Check if the split parts length is 2 and second part contains a valid email pattern
                    if (parts.length === 2 && /\S+@\S+\.\S+/.test(parts[1])) {
                        userEmail = parts[1];
                    }
                    fetchToken(userEmail);
                } else {
                    setLoader(false);
                    window.location.href =
                        process.env.REACT_APP_NO_ACCESS_REDIRECT_URL;
                    // navigate("/", { state: { ReportingHub: true } });
                }
            });
        } else {
            setLoader(false);
            window.location.href = process.env.REACT_APP_NO_ACCESS_REDIRECT_URL;
            // navigate("/", { state: { ReportingHub: true } });
        }
    }, []);

    // set cookie

    return (
        <>
            {loader ? (
                <>
                    <Loader />
                </>
            ) : rateLimit ? (
                <RateLimit />
            ) : null}
        </>
    );
};

export default PortalClaim;
