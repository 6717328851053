import { Flex, Box, Grid, GridItem, Spinner } from "@chakra-ui/react";
import Header from "../Header/Header";
export function Loader() {
    return (
        <>
            <Grid bgColor={"transparent"} h={"100vh"}>
                <GridItem rowSpan={12} colSpan={12}>
                    <Box>
                        <Flex
                            h={"50vh"}
                            bgColor={"transparent"}
                            justifyContent={"center"}
                            alignContent={"center"}
                            alignItems={"center"}
                        >
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="red.500"
                                size="xl"
                            />
                            {/* Loader */}
                        </Flex>
                    </Box>
                </GridItem>
            </Grid>
        </>
    );
}

export function UserMessage() {
    return <>ErrorMsg</>;
}
export function getUserInitials(name) {
    const nameParts = name.split(" ").filter(Boolean); // Split the name and remove any empty strings
    const initials = nameParts
        .map((part) => part[0])
        .join("")
        .toUpperCase(); // Extract and join initials
    return initials;
}
