import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import NoPage from "./Components/NoPage/NoPage";
import Layout from "./Layout/Layout";
import Login from "./Components/RHLanding/ReportingHubLanding";
import { TokenContext } from "../src/Utility/UserContext";
import RouteHandler from "./Components/RouteHandler/RouteHandler";

function App() {
    return (
        <BrowserRouter>
            <RouteHandler />
        </BrowserRouter>
    );
}

export default App;
