import { Flex, Text, Grid, GridItem } from "@chakra-ui/react";
import Main from "../Main/Main";
import TokenExpired from "../TokenExpired/TokenExpired";
import "../Home/Home.css";
import mob from "../../Assets/mob.svg";
import tel from "../../Assets/tel.svg";
import email from "../../Assets/email.svg";
import React from "react";
import { getUserInitials } from "../Common/Common";
export default function Home(props) {
    return (
        <>
            <div className="headerSection">
                <div className="title">Carbon Pricing</div>
                <div className="subTitle">
                    Pricing on carbon and low-carbon commodity instruments
                    expertly curated for you
                </div>
            </div>
            <div className="tableSection">
                <div className="tableDetail">
                    {props.tokenExpired ? (
                        <TokenExpired {...props} />
                    ) : (
                        <>
                            <Main />
                            <div className="info">
                                These are indicative market prices, for firm
                                prices contact your account representative at
                                SCB. Access expires: &nbsp;
                                {props.info.expiry_date}.
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="footerSection">
                <div className={`circle userInitial`}>
                    {getUserInitials(props.info.broker_info[0].person_name)}
                </div>
                <div className="brokerInfo">
                    <div className="name">
                        <span>{props.info.broker_info[0].person_name}</span>
                        <span>|</span>
                        <span>Your Account Manager</span>
                    </div>
                    {props.info.customer_message.length > 0 && (
                        <div className="note">
                            <span className="noteText">
                                {props.info.customer_message}
                            </span>
                        </div>
                    )}
                    <div className="contactInfo">
                        <div className="contact">
                            <div className="innerContact">
                                <div>
                                    <span className="contactImg">
                                        <img src={mob}></img>
                                    </span>
                                    <span className="contactText">
                                        &nbsp;
                                        {
                                            props.info.broker_info[0]
                                                .contact_number_3_or_mobile
                                        }
                                    </span>
                                </div>
                                <div>
                                    <span className="contactImg">
                                        <img src={tel}></img>
                                    </span>
                                    <span className="contactText">
                                        &nbsp;
                                        {
                                            props.info.broker_info[0]
                                                .contact_number_2_or_ddi
                                        }
                                    </span>
                                </div>

                                <div>
                                    <span className="contactImg">
                                        <img src={email}></img>
                                    </span>
                                    <span className="contactText">
                                        &nbsp;
                                        <a
                                            href={`mailto:${props.info.broker_info[0].emails}`}
                                        >
                                            {props.info.broker_info[0].emails}
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="msgToMngr">
                            <a
                                href={`mailto:${props.info.broker_info[0].emails}`}
                                className="btn"
                            >
                                Message your Account Manager
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
