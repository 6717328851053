import { useEffect } from "react";
import { getHttpParamWithCiap } from "../../Utility/Http";
import { apiUrl } from "../../Utility/ApiConstants";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        getHttpParamWithCiap(
            `${apiUrl.fetchReportingHubAuthorizeUrl}`,
            "get"
        ).then((res) => {
            if (res === 403 || res === 0) {
                setRateLimit(true);
                setLoader(false);
            }
            if (res && res.data.status_code === 200) {
                // Redirect the user to the URL provided by the backend
                window.location.href = res.data.auth_url;
            } else {
                // Handle error cases here
                setLoader(false);
                // navigate("/");
                window.location.href =
                    process.env.REACT_APP_NO_ACCESS_REDIRECT_URL;
            }
        });
    }, []);

    return null;
};

export default Login;
