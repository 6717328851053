import { Flex, Text, Box, Grid, GridItem } from "@chakra-ui/react";
import "../Header/Header.css";
export default function NoPage() {
    return (
        <>
            <Grid gap={"32px"}>
                <GridItem
                    backgroundColor={"#ECF5F5"}
                    className="headerSection"
                    alignItems={"flex-start"}
                    alignSelf={"stretch"}
                    padding={{
                        base: "1%",
                        xl: "32px 64px",
                        lg: "32px 64px",
                        "2xl": "32px 64px",
                    }}
                    gap={"10px"}
                >
                    <Text
                        className="title"
                        alignSelf={"center"}
                        fontSize={"48px"}
                    >
                        Carbon Pricing
                    </Text>
                    <Text
                        className="subTitle"
                        fontSize={"20px"}
                        alignSelf={"center"}
                    >
                        Exclusive access to pricing on carbon and low-carbon
                        commodity instruments
                    </Text>
                </GridItem>
                <GridItem>
                    {/* <Box> */}
                    <Flex
                        flexDirection={"column"}
                        padding={{
                            base: "1%",
                            xl: "32px 200px",
                            lg: "32px 200px",
                            "2xl": "10% 20%",
                        }}
                        alignItems={"flex-start"}
                        alignSelf={"stretch"}
                        gap={"24px"}
                    >
                        <Text
                            color={"#474747"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"24px"}
                            letterSpacing={"-0.4px"}
                        >
                            CarbonAI offers <b>exclusive access</b> to pricing
                            on over 2,000 carbon and low-carbon commodity
                            instruments.
                        </Text>
                        <Text
                            color={"#474747"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"24px"}
                            letterSpacing={"-0.4px"}
                        >
                            To obtain pricing, please &nbsp;
                            <b>contact your account manager at SCB</b>, who will
                            be able to tailor the prices shown on this page to
                            your specific needs, chosen from our diverse range
                            of available products.
                        </Text>
                        <Text
                            color={"#474747"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"24px"}
                            letterSpacing={"-0.4px"}
                        >
                            CarbonAI can provide pricing for carbon projects on
                            all leading registries such as Verra, Gold Standard,
                            CDM, CAR, ACR and others. Project types include
                            <b> Carbon Removal</b> (such as mangrove, improved
                            forestry management, afforestation) and{" "}
                            <b>Carbon Avoidance</b> (such as cookstoves,
                            renewable energy, REDD+ CCB). Projects with CORSIA
                            pilot, phase 1 and 2, Article 6, ITMO features and
                            SAFc Scope 1 emission credits are available.
                        </Text>
                        <Text
                            color={"#474747"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"24px"}
                            letterSpacing={"-0.4px"}
                        >
                            In addition, pricing is available for Energy
                            Attribution Certificates (such as GO, REC, iREC,
                            REGO), Biofuels, Battery Materials and Agricultural
                            Markets.
                        </Text>
                        <Text
                            color={"#474747"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"24px"}
                            letterSpacing={"-0.4px"}
                        >
                            Please note that these prices are currently
                            <b> available exclusively to SCB clients</b>.
                        </Text>
                    </Flex>
                    {/* </Box> */}
                </GridItem>
            </Grid>
        </>
    );
}
export function RateLimit() {
    return (
        <>
            <Grid h={"100vh"} backgroundColor={"white"}>
                <GridItem rowSpan={12} colSpan={12}>
                    <Box>
                        <Flex>
                            <Text
                                fontSize={"l"}
                                color={"black"}
                                marginLeft={"10px"}
                            >
                                Blocked by WAF
                            </Text>
                        </Flex>
                    </Box>
                </GridItem>
            </Grid>
        </>
    );
}
