import { Route, Routes, useLocation } from "react-router-dom";
import NoPage from "../NoPage/NoPage";
import Layout from "../../Layout/Layout";
import Login from "../RHLanding/ReportingHubLanding";
import { TokenContext } from "../../../src/Utility/UserContext";
import PortalClaim from "../RHPortalClaim/PortalClaim";
import CarbonChatLanding from "../CarbonChat/CarbonChat";

function RouteHandler() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let confUrl = false;
    let componentToRender = <NoPage />;
    const token = query.get("token");
    const preview = query.get("preview");
    const isReportingHub = query.get("isReportingHub");
    let type;
    let value;

    if (token) {
        type = "token";
        value = token;
    } else if (preview) {
        type = "preview";
        value = preview;
    } else if (isReportingHub === "True") {
        type = "isReportingHub";
        value = "True";
        confUrl = true;

        componentToRender = <Login />;
    }

    if (type && (type === "token" || type === "preview")) {
        confUrl = true;
        componentToRender = (
            <TokenContext.Provider value={{ type, tokenValue: value }}>
                <Layout />
            </TokenContext.Provider>
        );
    }

    return (
        <Routes>
            <Route
                path="portals/portal"
                element={confUrl ? componentToRender : <NoPage />}
            ></Route>
            <Route path="portals/code" element={<PortalClaim />} />
            <Route path="carbonchat" element={<CarbonChatLanding />} />
            <Route path="/" element={<NoPage />} />
            <Route path="*" element={<NoPage />} />
        </Routes>
    );
}

export default RouteHandler;
