import { useEffect } from "react";

export default function CarbonChatLanding() {
    const redirecturl = process.env.REACT_APP_CARBON_CHAT_REDIRECT_URL;

    useEffect(() => {
        // check if redirectflag is present in session storage
        const redirectFlag = sessionStorage.getItem("redirectFlag");

        // if redirectflag is not present, set it to true and redirect to carbon chat
        if (!redirectFlag) {
            sessionStorage.setItem("redirectFlag", true);

            window.top.location.href =
                "https://devcarbonai.carbonmarketsiq.com/#/?redirecturl=" +
                redirecturl;
        } else {
            // check if redirected flag is present in the url
            // window.top.location.href =
            //     "https://carbonmarketsiq.com/ExternalLinks/index/83?rhversion=20240312111447-3";
            window.location.href = "https://devcarbonai.carbonmarketsiq.com/";
        }
    }, []);

    return null;
}
