import { Text, Box } from "@chakra-ui/react";
import "../TokenExpired/TokenExpired.css";
import error from "../../Assets/error.svg";
export default function TokenExpired(props) {
    return (
        <div className="tokenExpireDiv">
            <Box>
                <Text className="tokenExpireText">
                    <img src={error}></img>Your access to this information
                    expired on &nbsp;
                    {props.info.expiry_date}, please contact your SCB
                    representative.
                </Text>
            </Box>
        </div>
    );
}
