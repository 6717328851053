import Home from "../Components/Home/Home";
import Header from "../Components/Header/Header";
import NoPage, { RateLimit } from "../Components/NoPage/NoPage";
import { getHttpParamWithCiap, getHttpParamWithMdc } from "../Utility/Http";
import { useEffect, useState, useRef, useContext } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { apiUrl } from "../Utility/ApiConstants";
import { Loader } from "../Components/Common/Common";
import "../Layout/Layout.css";
import { TokenContext, MailChimpWriteContext } from "../Utility/UserContext";

export default function Layout(props) {
    const { type, tokenValue } = useContext(TokenContext);
    const validToken = useRef(false);
    const [loader, setLoader] = useState(true);
    const tokenExpired = useRef(false);
    const info = useRef([]);
    const deskPortalClick = useRef({
        email: "",
        desk: "",
    });
    const [rateLimit, setRateLimit] = useState(false);
    useEffect(() => {
        function portalPreview() {
            getHttpParamWithCiap(
                `${apiUrl.tokenValidate}${type}=${tokenValue}`,
                "get"
            ).then((res) => {
                if (res === 403 || res === 0) {
                    setRateLimit(true);
                    setLoader(false);
                    return;
                }
                if (res !== "error") {
                    if (res.data.status_code === 200) {
                        validToken.current = true;
                        info.current = res.data.info;
                        tokenExpired.current = res.data.expired;
                        if (
                            tokenExpired.current === false &&
                            type === "token"
                        ) {
                            deskPortalClick.current.email =
                                info.current.customer_email;
                            deskPortalClick.current.desk = info.current.desk;
                        }
                    } else {
                        validToken.current = false;
                    }
                } else {
                    validToken.current = false;
                }

                setLoader(false);
            });
        }
        if (type === "token") {
            getHttpParamWithMdc(
                `${apiUrl.createFBTrackableLink}${type}=${tokenValue}`,
                "post"
            ).then((res) => {
                if (res === 403 || res === 0) {
                    setRateLimit(true);
                    setLoader(false);
                    return;
                }
                if (res !== "error") {
                    if (res.data.status_code === 200) {
                        portalPreview();
                    } else {
                        validToken.current = false;
                        setLoader(false);
                    }
                } else {
                    validToken.current = false;
                    setLoader(false);
                }
            });
        } else {
            portalPreview();
        }
    }, [tokenValue, type]);

    function mailChimpFileWrite() {
        if (
            info.current.length <= 0 ||
            deskPortalClick.current.email === "" ||
            deskPortalClick.current.desk === "" ||
            deskPortalClick.current.email === undefined ||
            deskPortalClick.current.desk === undefined
        ) {
            return;
        }
        getHttpParamWithCiap(
            apiUrl.saveDeskPortalClicks,
            "post",
            deskPortalClick.current
        ).then((res) => {
            if (res === 403 || res === 0) {
                setRateLimit(true);
                setLoader(false);
            }
        });
    }

    return (
        <>
            {loader ? (
                <>
                    <Loader />
                </>
            ) : rateLimit ? (
                <RateLimit />
            ) : validToken.current ? (
                <div className="layout">
                    <MailChimpWriteContext.Provider
                        value={{ mailChimpFileWrite, setRateLimit }}
                    >
                        <Home
                            {...props}
                            desk={info.current.desk}
                            tokenExpired={tokenExpired.current}
                            info={info.current}
                        />
                    </MailChimpWriteContext.Provider>
                </div>
            ) : (
                <>
                    <NoPage />
                </>
            )}
        </>
    );
}
